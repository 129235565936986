let intl;

const intlUtils = {
    setIntl(intlObject) {
        intl = intlObject;
    },

    getIntl() {
        return intl;
    }
};

export default intlUtils;
