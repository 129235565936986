import axios from "../axios";
import errorHandler from "../error-handler";

export const getMeContext = () => {
    return new Promise((resolve, reject) => {
        axios
            .get(`/me`)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(errorHandler(error));
            });
    });
};
