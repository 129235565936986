import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { invalidateAccessToken, refreshAccessToken } from "../api/auth/auth";
import axios from "../api/axios";
import { getMeContext } from "../api/me/context";
import { USER_EMAIL_STORAGE_KEY } from "../constants";
import { getLanguageByISO } from "../utils/languageUtils";
import localStorageUtils from "../utils/localStorageUtil";
import trackingUtils from "../utils/trackingUtils";
import { LanguageContext } from "./Language";

export const AuthContext = React.createContext({});

export const AuthProvider = ({ children }) => {
    const { setLanguage } = useContext(LanguageContext);
    const refreshTokenTimeout = useRef();
    const [busy, setBusy] = useState(true);
    const [tokenData, setTokenData] = useState(null);
    const [me, setMe] = useState(null);

    const clearAuth = async () => {
        console.log("[VP] Auth: Clearing");
        setMe(null);
        setTokenData(null);
        setBusy(false);
        axios.defaults.headers["Authorization"] = null;
    };

    const fetchMeContext = useCallback(
        (callback) => {
            setBusy(true);
            getMeContext()
                .then((response) => {
                    const user = response.user;
                    setMe(response);

                    // Track user
                    trackingUtils.setUser(user);

                    // Save username in local storage for next time
                    localStorageUtils.setData(USER_EMAIL_STORAGE_KEY, user.username);

                    // Load preferred language
                    if (user.locale) {
                        const preferredLanguage = getLanguageByISO(user.locale);
                        if (preferredLanguage) {
                            setLanguage(preferredLanguage);
                        }
                    }

                    setBusy(false);
                    callback && callback(response);
                })
                .catch(() => {
                    clearAuth();
                    setBusy(false);
                });
        },
        [setLanguage]
    );

    useEffect(() => {
        console.log("[VP] Auth: Initializing");
        setBusy(true);
        refreshAccessToken()
            .then((response) => {
                setTokenData(response);
            })
            .catch(() => {
                clearAuth();
            });

        axios.interceptors.response.use(
            (response) => response,
            function (error) {
                if (error?.response?.status === 401) {
                    console.log("[VP] Unauthorized response");
                    setBusy(true);
                    window.location.reload();
                } else {
                    return Promise.reject(error);
                }
            }
        );
    }, []);

    useEffect(() => {
        // Clear auth if empty token data
        if (!tokenData || !tokenData.expiresAt || tokenData.expiresInSeconds < 30) {
            return;
        }

        // Update when to request new token
        clearTimeout(refreshTokenTimeout.current);
        const refreshTokenInSeconds = tokenData.expiresInSeconds - 5 - Math.floor(Math.random() * 15);
        console.log("[VP] Auth: Refresh token in " + refreshTokenInSeconds + " seconds.");
        if (refreshTokenInSeconds < 0) {
            return clearAuth();
        }

        // Set timeout to refresh token
        refreshTokenTimeout.current = setTimeout(() => {
            refreshAccessToken().then((response) => {
                setTokenData(response);
            });
        }, Math.min(refreshTokenInSeconds * 1000, 2147483647));

        // Update axios default headers
        axios.defaults.headers["Authorization"] = "Bearer " + tokenData.accessToken;

        // Refresh me
        fetchMeContext();
    }, [tokenData, fetchMeContext]);

    return (
        <AuthContext.Provider
            value={{
                busy,
                me,
                reloadMeContext: fetchMeContext,
                tokenData,
                setTokenData,
                signOut: () => {
                    invalidateAccessToken();
                    clearAuth();
                }
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
