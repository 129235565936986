import React, { useState, useEffect } from "react";
import moment from "moment";
import { IntlProvider } from "react-intl";
import { suggestedLanguage } from "../utils/languageUtils";

export const LanguageContext = React.createContext({});

export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState(suggestedLanguage);
    const [wordIso, setWordIso] = useState(suggestedLanguage.iso);
    const [messages, setMessages] = useState(null);

    useEffect(() => {
        import(`../assets/language/${language.locale}.json`).then((languageJson) => {
            setMessages(flattenMessages(languageJson));
            setWordIso(language.iso);
            moment.locale(language.locale);
        });
    }, [language]);

    return (
        <LanguageContext.Provider value={{ language, setLanguage, wordIso, setWordIso }}>
            <IntlProvider locale={language?.locale} messages={messages}>
                {messages ? children : null}
            </IntlProvider>
        </LanguageContext.Provider>
    );
};

/**
 * Flatten message object
 * react-intl only supports a flat message structure
 * @param nestedMessages
 * @param prefix
 * @returns {*}
 */
export const flattenMessages = (nestedMessages, prefix = "") => {
    return Object.keys(nestedMessages).reduce((messages, key) => {
        let value = nestedMessages[key];
        let prefixedKey = prefix ? `${prefix}.${key}` : key;

        if (typeof value === "string") {
            messages[prefixedKey] = value;
        } else {
            Object.assign(messages, flattenMessages(value, prefixedKey));
        }

        return messages;
    }, {});
};
