import { createBrowserHistory } from "history";
import moment from "moment";
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Router } from "react-router-dom";
import { DATE_TIME_FORMAT } from "./constants";

// App
import { App } from "./App";
import { AuthProvider } from "./contexts/Auth";
import { LanguageProvider } from "./contexts/Language";

// Images
import "./assets/images/vp-app-icon.png";
import "./assets/images/vp-email-logo.png";
import "./assets/images/vp-maskable-icon.png";
import "./assets/images/vp-sharing-image.jpeg";
import Curtain from "./components/misc/Curtain";

// Moment config
moment.defaultFormat = DATE_TIME_FORMAT;
moment.defaultFormatUtc = DATE_TIME_FORMAT;

// Vibrate
navigator.vibrate = navigator.vibrate || navigator.webkitVibrate || navigator.mozVibrate || navigator.msVibrate;

// Method for extracting nested object property
// Ref here: http://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-with-string-key
Object.byString = function (o, s) {
    s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
    s = s.replace(/^\./, ""); // strip a leading dot
    let a = s.split(".");
    for (let i = 0, n = a.length; i < n; ++i) {
        const k = a[i];
        if (k in o) {
            o = o[k];
        } else {
            return;
        }
    }
    return o;
};

/**
 * ROUTING
 * --------------------------------------------------------
 */

// Create root element
const rootElement = document.createElement("div");
rootElement.id = "app";
document.body.appendChild(rootElement);

// Browser history
const history = createBrowserHistory();

const root = ReactDOM.createRoot(document.getElementById("app"));
root.render(
    <Router history={history} basepath="/">
        <LanguageProvider>
            <AuthProvider>
                <Suspense fallback={<Curtain />}>
                    <App />
                </Suspense>
            </AuthProvider>
        </LanguageProvider>
    </Router>
);
