import React, { lazy, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { Container } from "./components/layout/Container";
import { CookieConsent } from "./components/misc/CookieConsent/CookieConsent";
import { RouteAuth } from "./components/route/RouteAuth";

// Global styles
import { PhotoProvider } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import { Spinner } from "./components/loaders/Spinner";
import { WindowResizeListener } from "./components/utils/WindowResizeListener";
import "./styles/main.scss";
import { useDebounce } from "./utils/hooks";
import trackingUtils from "./utils/trackingUtils";

const Front = lazy(() =>
    import(/* webpackChunkName: "boarding" */ "./routes/front/Front").then((module) => ({ default: module.Front }))
);
const Pricing = lazy(() =>
    import(/* webpackChunkName: "boarding" */ "./routes/front/Pricing").then((module) => ({ default: module.Pricing }))
);
const Contact = lazy(() =>
    import(/* webpackChunkName: "boarding" */ "./routes/front/Contact").then((module) => ({ default: module.Contact }))
);
const Login = lazy(() =>
    import(/* webpackChunkName: "boarding" */ "./routes/boarding/Login").then((module) => ({ default: module.Login }))
);
const SignUp = lazy(() =>
    import(/* webpackChunkName: "boarding" */ "./routes/boarding/SignUp").then((module) => ({ default: module.SignUp }))
);
const SignUpContinue = lazy(() =>
    import(/* webpackChunkName: "boarding" */ "./routes/boarding/SignUpContinue").then((module) => ({
        default: module.SignUpContinue
    }))
);
const ForgotPassword = lazy(() =>
    import(/* webpackChunkName: "boarding" */ "./routes/boarding/ForgotPassword").then((module) => ({
        default: module.ForgotPassword
    }))
);
const SignOut = lazy(() =>
    import(/* webpackChunkName: "boarding" */ "./routes/boarding/SignOut").then((module) => ({
        default: module.SignOut
    }))
);
const VerifyEmail = lazy(() =>
    import(/* webpackChunkName: "boarding" */ "./routes/boarding/VerifyEmail").then((module) => ({
        default: module.VerifyEmail
    }))
);
const PageNotFound = lazy(() =>
    import(/* webpackChunkName: "boarding" */ "./routes/boarding/PageNotFound").then((module) => ({
        default: module.PageNotFound
    }))
);

const Terms = lazy(() =>
    import(/* webpackChunkName: "boarding" */ "./routes/misc/terms/Terms").then((module) => ({ default: module.Terms }))
);
const PrivacyPolicy = lazy(() =>
    import(/* webpackChunkName: "boarding" */ "./routes/misc/privacy/PrivacyPolicy").then((module) => ({
        default: module.PrivacyPolicy
    }))
);

const MeRoot = lazy(() =>
    import(/* webpackChunkName: "me" */ "./routes/me/MeRoot").then((module) => ({ default: module.MeRoot }))
);
const OrganizationRoot = lazy(() =>
    import(/* webpackChunkName: "org" */ "./routes/organization/OrganizationRoot").then((module) => ({
        default: module.OrganizationRoot
    }))
);
const CourseRoot = lazy(() =>
    import(/* webpackChunkName: "course" */ "./routes/course/CourseRoot").then((module) => ({
        default: module.CourseRoot
    }))
);
const VesselRoot = lazy(() =>
    import(/* webpackChunkName: "vessel" */ "./routes/vessel/VesselRoot").then((module) => ({
        default: module.VesselRoot
    }))
);
const AdminRoot = lazy(() =>
    import(/* webpackChunkName: "admin" */ "./routes/admin/AdminRoot").then((module) => ({
        default: module.AdminRoot
    }))
);

const UserLogReviewReport = lazy(() =>
    import(/* webpackChunkName: "reports" */ "./routes/reports/user_log_review/UserLogReviewReport").then((module) => ({
        default: module.UserLogReviewReport
    }))
);

const StyleGuideRoot = lazy(() =>
    import(/* webpackChunkName: "style-guide" */ "./routes/style-guide/StyleGuideRoot").then((module) => ({
        default: module.StyleGuideRoot
    }))
);

export const AppContext = React.createContext({});

export const App = () => {
    const location = useLocation();
    const [showSidebar, setShowSidebar] = useState(false);
    const [hasSidebar, setHasSidebar] = useState(false);
    const [pageInfo, setPageInfo] = useState({
        windowWidth: typeof window !== "undefined" ? window.innerWidth : 0,
        windowHeight: typeof window !== "undefined" ? window.innerHeight : 0,
        breakpoint: null
    });
    const [scrollContainerElement, setScrollContainerElement] = useState(document.body);

    useDebounce(
        () => {
            trackingUtils.trackPageView(location);
            scrollContainerElement.scrollIntoView({ block: "start", behavior: "instant" });
        },
        [location, scrollContainerElement],
        { delay: 100 }
    );

    return (
        <AppContext.Provider
            value={{
                showSidebar,
                setShowSidebar,
                hasSidebar,
                setHasSidebar,
                pageInfo,
                setPageInfo,
                scrollContainerElement,
                setScrollContainerElement
            }}
        >
            <PhotoProvider loadingElement={<Spinner contrast />}>
                <Container>
                    <Switch>
                        {/* Front */}
                        <Route path="/" exact component={Front} />
                        <Route path="/pricing" exact component={Pricing} />
                        <Route path="/contact" exact component={Contact} />

                        {/* Boarding */}
                        <Route path="/signout" component={SignOut} />
                        <Route path="/signup" exact component={SignUp} />
                        <Route path="/signup/continue/:key" component={SignUpContinue} />
                        <Route path="/forgot" component={ForgotPassword} />

                        {/* Boarding (used by Auth server) */}
                        <Route path="/login" component={Login} />

                        {/* Course */}
                        <Route path="/courses" component={CourseRoot} />

                        {/* Misc */}
                        <Route path="/terms" component={Terms} />
                        <Route path="/privacy" component={PrivacyPolicy} />

                        {/* Verifications */}
                        <Route path="/verify/email/:key" component={VerifyEmail} />

                        {/* Me */}
                        <RouteAuth path="/me" component={MeRoot} />

                        {/* Organization */}
                        <RouteAuth path="/org/:organizationId" component={OrganizationRoot} />

                        {/* Vessel */}
                        <RouteAuth path="/vessels/:vesselId" component={VesselRoot} />

                        {/* Admin */}
                        <RouteAuth path="/admin" component={AdminRoot} roles="admin" />

                        {/* Reports */}
                        <Route path="/reports/user_log_review/:reviewUuid" component={UserLogReviewReport} />

                        {/* Style guide */}
                        <Route path="/style-guide" component={StyleGuideRoot} />

                        {/* Page not found */}
                        <Route component={PageNotFound} />
                    </Switch>
                    {/* Cookie consent */}
                    {["/"].includes(location.pathname) && <CookieConsent />}
                </Container>
            </PhotoProvider>
            <WindowResizeListener />
        </AppContext.Provider>
    );
};
