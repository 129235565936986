export const countryList = [
    {
        name: "Afghanistan",
        dialCode: "93",
        iso: "AF"
    },
    {
        name: "Aland Islands",
        dialCode: "358",
        iso: "AX"
    },
    {
        name: "Albania",
        dialCode: "355",
        iso: "AL"
    },
    {
        name: "Algeria",
        dialCode: "213",
        iso: "DZ"
    },
    {
        name: "American Samoa",
        dialCode: "1-684",
        iso: "AS"
    },
    {
        name: "Andorra",
        dialCode: "376",
        iso: "AD"
    },
    {
        name: "Angola",
        dialCode: "244",
        iso: "AO"
    },
    {
        name: "Anguilla",
        dialCode: "1-264",
        iso: "AI"
    },
    {
        name: "Antarctica",
        dialCode: "672",
        iso: "AQ"
    },
    {
        name: "Antigua and Barbuda",
        dialCode: "1268",
        iso: "AG"
    },
    {
        name: "Argentina",
        dialCode: "54",
        iso: "AR"
    },
    {
        name: "Armenia",
        dialCode: "374",
        iso: "AM"
    },
    {
        name: "Aruba",
        dialCode: "297",
        iso: "AW"
    },
    {
        name: "Australia",
        dialCode: "61",
        iso: "AU",
        taxTypes: [
            {
                key: "au_abn",
                description: "Australian Business Number (AU ABN)",
                example: "12345678912"
            },
            {
                key: "au_arn",
                description: "Australian Taxation Office Reference Number",
                example: "123456789123"
            }
        ]
    },
    {
        name: "Austria",
        dialCode: "43",
        iso: "AT",
        taxTypes: [
            {
                key: "eu_vat",
                description: "European VAT number",
                example: "ATU12345678"
            }
        ]
    },
    {
        name: "Azerbaijan",
        dialCode: "994",
        iso: "AZ"
    },
    {
        name: "Bahamas",
        dialCode: "1-242",
        iso: "BS"
    },
    {
        name: "Bahrain",
        dialCode: "973",
        iso: "BH"
    },
    {
        name: "Bangladesh",
        dialCode: "880",
        iso: "BD"
    },
    {
        name: "Barbados",
        dialCode: "1-246",
        iso: "BB"
    },
    {
        name: "Belarus",
        dialCode: "375",
        iso: "BY"
    },
    {
        name: "Belgium",
        dialCode: "32",
        iso: "BE",
        taxTypes: [
            {
                key: "eu_vat",
                description: "European VAT number",
                example: "BE0123456789"
            }
        ]
    },
    {
        name: "Belize",
        dialCode: "501",
        iso: "BZ"
    },
    {
        name: "Benin",
        dialCode: "229",
        iso: "BJ"
    },
    {
        name: "Bermuda",
        dialCode: "1-441",
        iso: "BM"
    },
    {
        name: "Bhutan",
        dialCode: "975",
        iso: "BT"
    },
    {
        name: "Bolivia, Plurinational State of",
        dialCode: "591",
        iso: "BO"
    },
    {
        name: "Bosnia and Herzegovina",
        dialCode: "387",
        iso: "BA"
    },
    {
        name: "Botswana",
        dialCode: "267",
        iso: "BW"
    },
    {
        name: "Brazil",
        dialCode: "55",
        iso: "BR",
        taxTypes: [
            {
                key: "br_cnpj",
                description: "Brazilian CNPJ number",
                example: "01.234.456/5432-10"
            },
            {
                key: "br_cpf",
                description: "Brazilian CPF number",
                example: "123.456.789-87"
            }
        ]
    },
    {
        name: "British Indian Ocean Territory",
        dialCode: "246",
        iso: "IO"
    },
    {
        name: "Brunei Darussalam",
        dialCode: "673",
        iso: "BN"
    },
    {
        name: "Bulgaria",
        dialCode: "359",
        iso: "BG",
        taxTypes: [
            {
                key: "eu_vat",
                description: "European VAT number",
                example: "BG0123456789"
            }
        ]
    },
    {
        name: "Burkina Faso",
        dialCode: "226",
        iso: "BF"
    },
    {
        name: "Burundi",
        dialCode: "257",
        iso: "BI"
    },
    {
        name: "Cambodia",
        dialCode: "855",
        iso: "KH"
    },
    {
        name: "Cameroon",
        dialCode: "237",
        iso: "CM"
    },
    {
        name: "Canada",
        dialCode: "1",
        iso: "CA",
        taxTypes: [
            {
                key: "ca_bn",
                description: "Canadian BN",
                example: "123456789"
            },
            {
                key: "ca_gst_hst",
                description: "Canadian GST/HST number",
                example: "123456789RT0002"
            },
            {
                key: "ca_pst_bc",
                description: "Canadian PST number (British Columbia)",
                example: "PST-1234-5678"
            },
            {
                key: "ca_pst_mb",
                description: "Canadian PST number (Manitoba)",
                example: "123456-7"
            },
            {
                key: "ca_pst_sk",
                description: "Canadian PST number (Saskatchewan)",
                example: "1234567"
            },
            {
                key: "ca_qst",
                description: "Canadian QST number (Québec)",
                example: "1234567890TQ1234"
            }
        ]
    },
    {
        name: "Cape Verde",
        dialCode: "238",
        iso: "CV"
    },
    {
        name: "Cayman Islands",
        dialCode: "345",
        iso: "KY"
    },
    {
        name: "Central African Republic",
        dialCode: "236",
        iso: "CF"
    },
    {
        name: "Chad",
        dialCode: "235",
        iso: "TD"
    },
    {
        name: "Chile",
        dialCode: "56",
        iso: "CL",
        taxTypes: [
            {
                key: "cl_tin",
                description: "Chilean TIN",
                example: "12.345.678-K"
            }
        ]
    },
    {
        name: "China",
        dialCode: "86",
        iso: "CN"
    },
    {
        name: "Christmas Island",
        dialCode: "61",
        iso: "CX"
    },
    {
        name: "Cocos (Keeling) Islands",
        dialCode: "61",
        iso: "CC"
    },
    {
        name: "Colombia",
        dialCode: "57",
        iso: "CO"
    },
    {
        name: "Comoros",
        dialCode: "269",
        iso: "KM"
    },
    {
        name: "Congo",
        dialCode: "242",
        iso: "CG"
    },
    {
        name: "Congo, The Democratic Republic of the Congo",
        dialCode: "243",
        iso: "CD"
    },
    {
        name: "Cook Islands",
        dialCode: "682",
        iso: "CK"
    },
    {
        name: "Costa Rica",
        dialCode: "506",
        iso: "CR"
    },
    {
        name: "Cote d'Ivoire",
        dialCode: "225",
        iso: "CI"
    },
    {
        name: "Croatia",
        dialCode: "385",
        iso: "HR",
        taxTypes: [
            {
                key: "eu_vat",
                description: "European VAT number",
                example: "HR12345678912"
            }
        ]
    },
    {
        name: "Cuba",
        dialCode: "53",
        iso: "CU"
    },
    {
        name: "Cyprus",
        dialCode: "357",
        iso: "CY",
        taxTypes: [
            {
                key: "eu_vat",
                description: "European VAT number",
                example: "CY12345678Z"
            }
        ]
    },
    {
        name: "Czech Republic",
        dialCode: "420",
        iso: "CZ",
        taxTypes: [
            {
                key: "eu_vat",
                description: "European VAT number",
                example: "CZ1234567890"
            }
        ]
    },
    {
        name: "Denmark",
        dialCode: "45",
        iso: "DK",
        taxTypes: [
            {
                key: "eu_vat",
                description: "European VAT number",
                example: "DK12345678"
            }
        ]
    },
    {
        name: "Djibouti",
        dialCode: "253",
        iso: "DJ"
    },
    {
        name: "Dominica",
        dialCode: "1-767",
        iso: "DM"
    },
    {
        name: "Dominican Republic",
        dialCode: "1-849",
        iso: "DO"
    },
    {
        name: "Ecuador",
        dialCode: "593",
        iso: "EC"
    },
    {
        name: "Egypt",
        dialCode: "20",
        iso: "EG"
    },
    {
        name: "El Salvador",
        dialCode: "503",
        iso: "SV"
    },
    {
        name: "Equatorial Guinea",
        dialCode: "240",
        iso: "GQ"
    },
    {
        name: "Eritrea",
        dialCode: "291",
        iso: "ER"
    },
    {
        name: "Estonia",
        dialCode: "372",
        iso: "EE",
        taxTypes: [
            {
                key: "eu_vat",
                description: "European VAT number",
                example: "EE123456789"
            }
        ]
    },
    {
        name: "Ethiopia",
        dialCode: "251",
        iso: "ET"
    },
    {
        name: "Falkland Islands (Malvinas)",
        dialCode: "500",
        iso: "FK"
    },
    {
        name: "Faroe Islands",
        dialCode: "298",
        iso: "FO"
    },
    {
        name: "Fiji",
        dialCode: "679",
        iso: "FJ"
    },
    {
        name: "Finland",
        dialCode: "358",
        iso: "FI",
        taxTypes: [
            {
                key: "eu_vat",
                description: "European VAT number",
                example: "FI12345678"
            }
        ]
    },
    {
        name: "France",
        dialCode: "33",
        iso: "FR",
        taxTypes: [
            {
                key: "eu_vat",
                description: "European VAT number",
                example: "FRAB123456789"
            }
        ]
    },
    {
        name: "French Guiana",
        dialCode: "594",
        iso: "GF"
    },
    {
        name: "French Polynesia",
        dialCode: "689",
        iso: "PF"
    },
    {
        name: "Gabon",
        dialCode: "241",
        iso: "GA"
    },
    {
        name: "Gambia",
        dialCode: "220",
        iso: "GM"
    },
    {
        name: "Georgia",
        dialCode: "995",
        iso: "GE",
        taxTypes: [
            {
                key: "ge_vat",
                description: "Georgian VAT",
                example: "123456789"
            }
        ]
    },
    {
        name: "Germany",
        dialCode: "49",
        iso: "DE",
        taxTypes: [
            {
                key: "eu_vat",
                description: "European VAT number",
                example: "DE123456789"
            }
        ]
    },
    {
        name: "Ghana",
        dialCode: "233",
        iso: "GH"
    },
    {
        name: "Gibraltar",
        dialCode: "350",
        iso: "GI"
    },
    {
        name: "Greece",
        dialCode: "30",
        iso: "GR",
        taxTypes: [
            {
                key: "eu_vat",
                description: "European VAT number",
                example: "EL123456789"
            }
        ]
    },
    {
        name: "Greenland",
        dialCode: "299",
        iso: "GL"
    },
    {
        name: "Grenada",
        dialCode: "1-473",
        iso: "GD"
    },
    {
        name: "Guadeloupe",
        dialCode: "590",
        iso: "GP"
    },
    {
        name: "Guam",
        dialCode: "1-671",
        iso: "GU"
    },
    {
        name: "Guatemala",
        dialCode: "502",
        iso: "GT"
    },
    {
        name: "Guinea",
        dialCode: "224",
        iso: "GN"
    },
    {
        name: "Guinea-Bissau",
        dialCode: "245",
        iso: "GW"
    },
    {
        name: "Guyana",
        dialCode: "595",
        iso: "GY"
    },
    {
        name: "Haiti",
        dialCode: "509",
        iso: "HT"
    },
    {
        name: "Holy See (Vatican City State)",
        dialCode: "379",
        iso: "VA"
    },
    {
        name: "Honduras",
        dialCode: "504",
        iso: "HN"
    },
    {
        name: "Hong Kong",
        dialCode: "852",
        iso: "HK",
        taxTypes: [
            {
                key: "hk_br",
                description: "Hong Kong BR number",
                example: "12345678"
            }
        ]
    },
    {
        name: "Hungary",
        dialCode: "36",
        iso: "HU",
        taxTypes: [
            {
                key: "eu_vat",
                description: "European VAT number",
                example: "HU12345678912"
            }
        ]
    },
    {
        name: "Iceland",
        dialCode: "354",
        iso: "IS"
    },
    {
        name: "India",
        dialCode: "91",
        iso: "IN",
        taxTypes: [
            {
                key: "in_gst",
                description: "Indian GST number",
                example: "12ABCDE3456FGZH"
            }
        ]
    },
    {
        name: "Indonesia",
        dialCode: "62",
        iso: "ID",
        taxTypes: [
            {
                key: "id_npwp",
                description: "Indonesian NPWP number",
                example: "12.345.678.9-012.345"
            }
        ]
    },
    {
        name: "Iran, Islamic Republic of Persian Gulf",
        dialCode: "98",
        iso: "IR"
    },
    {
        name: "Iraq",
        dialCode: "964",
        iso: "IQ"
    },
    {
        name: "Ireland",
        dialCode: "353",
        iso: "IE",
        taxTypes: [
            {
                key: "eu_vat",
                description: "European VAT number",
                example: "IE1234567AB"
            }
        ]
    },
    {
        name: "Israel",
        dialCode: "972",
        iso: "IL",
        taxTypes: [
            {
                key: "il_vat",
                description: "Israel VAT",
                example: "000012345"
            }
        ]
    },
    {
        name: "Italy",
        dialCode: "39",
        iso: "IT",
        taxTypes: [
            {
                key: "eu_vat",
                description: "European VAT number",
                example: "IT12345678912"
            }
        ]
    },
    {
        name: "Jamaica",
        dialCode: "1-876",
        iso: "JM"
    },
    {
        name: "Japan",
        dialCode: "81",
        iso: "JP",
        taxTypes: [
            {
                key: "jp_cn",
                description: "Japanese Corporate Number (*Hōjin Bangō*)",
                example: "1234567891234"
            },
            {
                key: "jp_rn",
                description:
                    "Japanese Registered Foreign Businesses' Registration Number (*Tōroku Kokugai Jigyōsha no Tōroku Bangō*)",
                example: "12345"
            }
        ]
    },
    {
        name: "Jordan",
        dialCode: "962",
        iso: "JO"
    },
    {
        name: "Kazakhstan",
        dialCode: "7",
        iso: "KZ"
    },
    {
        name: "Kenya",
        dialCode: "254",
        iso: "KE"
    },
    {
        name: "Kiribati",
        dialCode: "686",
        iso: "KI"
    },
    {
        name: "Korea, Democratic People's Republic of Korea",
        dialCode: "850",
        iso: "KP"
    },
    {
        name: "Korea, Republic of South Korea",
        dialCode: "82",
        iso: "KR",
        taxTypes: [
            {
                key: "kr_brn",
                description: "Korean BRN",
                example: "123-45-67890"
            }
        ]
    },
    {
        name: "Kuwait",
        dialCode: "965",
        iso: "KW"
    },
    {
        name: "Kyrgyzstan",
        dialCode: "996",
        iso: "KG"
    },
    {
        name: "Laos",
        dialCode: "856",
        iso: "LA"
    },
    {
        name: "Latvia",
        dialCode: "371",
        iso: "LV",
        taxTypes: [
            {
                key: "eu_vat",
                description: "European VAT number",
                example: "LV12345678912"
            }
        ]
    },
    {
        name: "Lebanon",
        dialCode: "961",
        iso: "LB"
    },
    {
        name: "Lesotho",
        dialCode: "266",
        iso: "LS"
    },
    {
        name: "Liberia",
        dialCode: "231",
        iso: "LR"
    },
    {
        name: "Libyan Arab Jamahiriya",
        dialCode: "218",
        iso: "LY"
    },
    {
        name: "Liechtenstein",
        dialCode: "423",
        iso: "LI",
        taxTypes: [
            {
                key: "li_uid",
                description: "Liechtensteinian UID number",
                example: "CHE123456789"
            }
        ]
    },
    {
        name: "Lithuania",
        dialCode: "370",
        iso: "LT",
        taxTypes: [
            {
                key: "eu_vat",
                description: "European VAT number",
                example: "LT123456789123"
            }
        ]
    },
    {
        name: "Luxembourg",
        dialCode: "352",
        iso: "LU",
        taxTypes: [
            {
                key: "eu_vat",
                description: "European VAT number",
                example: "LU12345678"
            }
        ]
    },
    {
        name: "Macao",
        dialCode: "853",
        iso: "MO"
    },
    {
        name: "Macedonia",
        dialCode: "389",
        iso: "MK"
    },
    {
        name: "Madagascar",
        dialCode: "261",
        iso: "MG"
    },
    {
        name: "Malawi",
        dialCode: "265",
        iso: "MW"
    },
    {
        name: "Malaysia",
        dialCode: "60",
        iso: "MY",
        taxTypes: [
            {
                key: "my_frp",
                description: "Malaysian FRP number",
                example: "12345678"
            },
            {
                key: "my_itn",
                description: "Malaysian ITN",
                example: "C 1234567890"
            }
        ]
    },
    {
        name: "Maldives",
        dialCode: "960",
        iso: "MV"
    },
    {
        name: "Mali",
        dialCode: "223",
        iso: "ML"
    },
    {
        name: "Malta",
        dialCode: "356",
        iso: "MT",
        taxTypes: [
            {
                key: "eu_vat",
                description: "European VAT number",
                example: "MT12345678"
            }
        ]
    },
    {
        name: "Marshall Islands",
        dialCode: "692",
        iso: "MH"
    },
    {
        name: "Martinique",
        dialCode: "596",
        iso: "MQ"
    },
    {
        name: "Mauritania",
        dialCode: "222",
        iso: "MR"
    },
    {
        name: "Mauritius",
        dialCode: "230",
        iso: "MU"
    },
    {
        name: "Mayotte",
        dialCode: "262",
        iso: "YT"
    },
    {
        name: "Mexico",
        dialCode: "52",
        iso: "MX",
        taxTypes: [
            {
                key: "mx_rfc",
                description: "Mexican RFC number",
                example: "ABC010203AB9"
            }
        ]
    },
    {
        name: "Micronesia, Federated States of Micronesia",
        dialCode: "691",
        iso: "FM"
    },
    {
        name: "Moldova",
        dialCode: "373",
        iso: "MD"
    },
    {
        name: "Monaco",
        dialCode: "377",
        iso: "MC"
    },
    {
        name: "Mongolia",
        dialCode: "976",
        iso: "MN"
    },
    {
        name: "Montenegro",
        dialCode: "382",
        iso: "ME"
    },
    {
        name: "Montserrat",
        dialCode: "1664",
        iso: "MS"
    },
    {
        name: "Morocco",
        dialCode: "212",
        iso: "MA"
    },
    {
        name: "Mozambique",
        dialCode: "258",
        iso: "MZ"
    },
    {
        name: "Myanmar",
        dialCode: "95",
        iso: "MM"
    },
    {
        name: "Namibia",
        dialCode: "264",
        iso: "NA"
    },
    {
        name: "Nauru",
        dialCode: "674",
        iso: "NR"
    },
    {
        name: "Nepal",
        dialCode: "977",
        iso: "NP"
    },
    {
        name: "Netherlands",
        dialCode: "31",
        iso: "NL",
        taxTypes: [
            {
                key: "eu_vat",
                description: "European VAT number",
                example: "NL123456789B12"
            }
        ]
    },
    {
        name: "Netherlands Antilles",
        dialCode: "599",
        iso: "AN"
    },
    {
        name: "New Caledonia",
        dialCode: "687",
        iso: "NC"
    },
    {
        name: "New Zealand",
        dialCode: "64",
        iso: "NZ",
        taxTypes: [
            {
                key: "nz_gst",
                description: "New Zealand GST number",
                example: "123456789"
            }
        ]
    },
    {
        name: "Nicaragua",
        dialCode: "505",
        iso: "NI"
    },
    {
        name: "Niger",
        dialCode: "227",
        iso: "NE"
    },
    {
        name: "Nigeria",
        dialCode: "234",
        iso: "NG"
    },
    {
        name: "Niue",
        dialCode: "683",
        iso: "NU"
    },
    {
        name: "Norfolk Island",
        dialCode: "672",
        iso: "NF"
    },
    {
        name: "Northern Mariana Islands",
        dialCode: "1 670",
        iso: "MP"
    },
    {
        name: "Norway",
        dialCode: "47",
        iso: "NO",
        taxTypes: [
            {
                key: "no_vat",
                description: "Norwegian VAT number",
                example: "123456789MVA"
            }
        ]
    },
    {
        name: "Oman",
        dialCode: "968",
        iso: "OM"
    },
    {
        name: "Pakistan",
        dialCode: "92",
        iso: "PK"
    },
    {
        name: "Palau",
        dialCode: "680",
        iso: "PW"
    },
    {
        name: "Palestinian Territory, Occupied",
        dialCode: "970",
        iso: "PS"
    },
    {
        name: "Panama",
        dialCode: "507",
        iso: "PA"
    },
    {
        name: "Papua New Guinea",
        dialCode: "675",
        iso: "PG"
    },
    {
        name: "Paraguay",
        dialCode: "595",
        iso: "PY"
    },
    {
        name: "Peru",
        dialCode: "51",
        iso: "PE"
    },
    {
        name: "Philippines",
        dialCode: "63",
        iso: "PH"
    },
    {
        name: "Pitcairn",
        dialCode: "872",
        iso: "PN"
    },
    {
        name: "Poland",
        dialCode: "48",
        iso: "PL",
        taxTypes: [
            {
                key: "eu_vat",
                description: "European VAT number",
                example: "PL1234567890"
            }
        ]
    },
    {
        name: "Portugal",
        dialCode: "351",
        iso: "PT",
        taxTypes: [
            {
                key: "eu_vat",
                description: "European VAT number",
                example: "PT123456789"
            }
        ]
    },
    {
        name: "Puerto Rico",
        dialCode: "1 939",
        iso: "PR"
    },
    {
        name: "Qatar",
        dialCode: "974",
        iso: "QA"
    },
    {
        name: "Romania",
        dialCode: "40",
        iso: "RO",
        taxTypes: [
            {
                key: "eu_vat",
                description: "European VAT number",
                example: "RO1234567891"
            }
        ]
    },
    {
        name: "Russia",
        dialCode: "7",
        iso: "RU",
        taxTypes: [
            {
                key: "ru_inn",
                description: "Russian INN",
                example: "1234567891"
            },
            {
                key: "ru_kpp",
                description: "Russian KPP",
                example: "123456789"
            }
        ]
    },
    {
        name: "Rwanda",
        dialCode: "250",
        iso: "RW"
    },
    {
        name: "Saint Barthelemy",
        dialCode: "590",
        iso: "BL"
    },
    {
        name: "Saint Helena, Ascension and Tristan Da Cunha",
        dialCode: "290",
        iso: "SH"
    },
    {
        name: "Saint Kitts and Nevis",
        dialCode: "1-869",
        iso: "KN"
    },
    {
        name: "Saint Lucia",
        dialCode: "1-758",
        iso: "LC"
    },
    {
        name: "Saint Martin",
        dialCode: "590",
        iso: "MF"
    },
    {
        name: "Saint Pierre and Miquelon",
        dialCode: "508",
        iso: "PM"
    },
    {
        name: "Saint Vincent and the Grenadines",
        dialCode: "1-784",
        iso: "VC"
    },
    {
        name: "Samoa",
        dialCode: "685",
        iso: "WS"
    },
    {
        name: "San Marino",
        dialCode: "378",
        iso: "SM"
    },
    {
        name: "Sao Tome and Principe",
        dialCode: "239",
        iso: "ST"
    },
    {
        name: "Saudi Arabia",
        dialCode: "966",
        iso: "SA",
        taxTypes: [
            {
                key: "sa_vat",
                description: "Saudi Arabia VAT",
                example: "123456789012345"
            }
        ]
    },
    {
        name: "Senegal",
        dialCode: "221",
        iso: "SN"
    },
    {
        name: "Serbia",
        dialCode: "381",
        iso: "RS"
    },
    {
        name: "Seychelles",
        dialCode: "248",
        iso: "SC"
    },
    {
        name: "Sierra Leone",
        dialCode: "232",
        iso: "SL"
    },
    {
        name: "Singapore",
        dialCode: "65",
        iso: "SG",
        taxTypes: [
            {
                key: "sg_gst",
                description: "Singaporean GST",
                example: "M12345678X"
            },
            {
                key: "sg_uen",
                description: "Singaporean UEN",
                example: "123456789F"
            }
        ]
    },
    {
        name: "Slovakia",
        dialCode: "421",
        iso: "SK",
        taxTypes: [
            {
                key: "eu_vat",
                description: "European VAT number",
                example: "SK1234567891"
            }
        ]
    },
    {
        name: "Slovenia",
        dialCode: "386",
        iso: "SI",
        taxTypes: [
            {
                key: "eu_vat",
                description: "European VAT number",
                example: "SI12345678"
            }
        ]
    },
    {
        name: "Solomon Islands",
        dialCode: "677",
        iso: "SB"
    },
    {
        name: "Somalia",
        dialCode: "252",
        iso: "SO"
    },
    {
        name: "South Africa",
        dialCode: "27",
        iso: "ZA",
        taxTypes: [
            {
                key: "za_vat",
                description: "South African VAT number",
                example: "4123456789"
            }
        ]
    },
    {
        name: "South Georgia and the South Sandwich Islands",
        dialCode: "500",
        iso: "GS"
    },
    {
        name: "Spain",
        dialCode: "34",
        iso: "ES",
        taxTypes: [
            {
                key: "eu_vat",
                description: "European VAT number",
                example: "ESA1234567Z"
            },
            {
                key: "es_cif",
                description: "Spanish CIF number",
                example: "A12345678"
            }
        ]
    },
    {
        name: "Sri Lanka",
        dialCode: "94",
        iso: "LK"
    },
    {
        name: "Sudan",
        dialCode: "249",
        iso: "SD"
    },
    {
        name: "Suriname",
        dialCode: "597",
        iso: "SR"
    },
    {
        name: "Swaziland",
        dialCode: "268",
        iso: "SZ"
    },
    {
        name: "Sweden",
        dialCode: "46",
        iso: "SE",
        taxTypes: [
            {
                key: "eu_vat",
                description: "European VAT number",
                example: "SE123456789123"
            }
        ]
    },
    {
        name: "Switzerland",
        dialCode: "41",
        iso: "CH",
        taxTypes: [
            {
                key: "ch_vat",
                description: "Switzerland VAT number",
                example: "CHE-123.456.789 MWST"
            }
        ]
    },
    {
        name: "Syrian Arab Republic",
        dialCode: "963",
        iso: "SY"
    },
    {
        name: "Taiwan",
        dialCode: "886",
        iso: "TW",
        taxTypes: [
            {
                key: "tw_vat",
                description: "Taiwanese VAT",
                example: "12345678"
            }
        ]
    },
    {
        name: "Tajikistan",
        dialCode: "992",
        iso: "TJ"
    },
    {
        name: "Tanzania, United Republic of Tanzania",
        dialCode: "255",
        iso: "TZ"
    },
    {
        name: "Thailand",
        dialCode: "66",
        iso: "TH",
        taxTypes: [
            {
                key: "th_vat",
                description: "Thai VAT",
                example: "1234567891234"
            }
        ]
    },
    {
        name: "Timor-Leste",
        dialCode: "670",
        iso: "TL"
    },
    {
        name: "Togo",
        dialCode: "228",
        iso: "TG"
    },
    {
        name: "Tokelau",
        dialCode: "690",
        iso: "TK"
    },
    {
        name: "Tonga",
        dialCode: "676",
        iso: "TO"
    },
    {
        name: "Trinidad and Tobago",
        dialCode: "1-868",
        iso: "TT"
    },
    {
        name: "Tunisia",
        dialCode: "216",
        iso: "TN"
    },
    {
        name: "Turkey",
        dialCode: "90",
        iso: "TR"
    },
    {
        name: "Turkmenistan",
        dialCode: "993",
        iso: "TM"
    },
    {
        name: "Turks and Caicos Islands",
        dialCode: "1-649",
        iso: "TC"
    },
    {
        name: "Tuvalu",
        dialCode: "688",
        iso: "TV"
    },
    {
        name: "Uganda",
        dialCode: "256",
        iso: "UG"
    },
    {
        name: "Ukraine",
        dialCode: "380",
        iso: "UA",
        taxTypes: [
            {
                key: "ua_vat",
                description: "Ukrainian VAT",
                example: "123456789"
            }
        ]
    },
    {
        name: "United Arab Emirates",
        dialCode: "971",
        iso: "AE",
        taxTypes: [
            {
                key: "ae_trn",
                description: "United Arab Emirates TRN",
                example: "123456789012345"
            }
        ]
    },
    {
        name: "United Kingdom",
        dialCode: "44",
        iso: "GB",
        taxTypes: [
            {
                key: "gb_vat",
                description: "United Kingdom VAT number",
                example: "GB123456789"
            },
            {
                key: "eu_vat",
                description: "Northern Ireland VAT number",
                example: "XI123456789"
            }
        ]
    },
    {
        name: "United States",
        dialCode: "1",
        iso: "US",
        taxTypes: [
            {
                key: "us_ein",
                description: "United States EIN",
                example: "12-3456789"
            }
        ]
    },
    {
        name: "Uruguay",
        dialCode: "598",
        iso: "UY"
    },
    {
        name: "Uzbekistan",
        dialCode: "998",
        iso: "UZ"
    },
    {
        name: "Vanuatu",
        dialCode: "678",
        iso: "VU"
    },
    {
        name: "Venezuela, Bolivarian Republic of Venezuela",
        dialCode: "58",
        iso: "VE"
    },
    {
        name: "Vietnam",
        dialCode: "84",
        iso: "VN"
    },
    {
        name: "Virgin Islands, British",
        dialCode: "1-284",
        iso: "VG"
    },
    {
        name: "Virgin Islands, U.S.",
        dialCode: "1-340",
        iso: "VI"
    },
    {
        name: "Wallis and Futuna",
        dialCode: "681",
        iso: "WF"
    },
    {
        name: "Yemen",
        dialCode: "967",
        iso: "YE"
    },
    {
        name: "Zambia",
        dialCode: "260",
        iso: "ZM"
    },
    {
        name: "Zimbabwe",
        dialCode: "263",
        iso: "ZW"
    }
];

export const getCountryDialCode = (iso) => {
    const country = countryList.filter((country) => {
        return country.iso === iso;
    });

    return country && country.length ? country[0].dialCode : null;
};

export const getCountryByIso = (iso) => {
    return countryList.filter((country) => country.iso === iso)[0];
};
