/**
 * Check if the given element has a parent with a given class name
 * @param {*} element
 * @param {*} classnames
 * @returns
 */
export const hasParentWithClassName = (element, classnames) => {
    while (element !== null) {
        const elementClassName = element.className?.toString().toLowerCase();
        if (elementClassName && elementClassName.indexOf(classnames.toLowerCase()) >= 0) {
            return true;
        }
        element = element.parentElement;
    }
    return false;
};
