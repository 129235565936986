/**
 * Local storage util
 */

const localStorageUtils = {
    /**
     * Set data
     * @param key
     * @param value
     */
    setData(key, value) {
        try {
            const _localStorage = localStorage || window.localStorage;
            if (value) {
                _localStorage.setItem(key, JSON.stringify(value));
            } else {
                _localStorage.removeItem(key);
            }
        } catch (ex) {
            console.warn("[VP] localStorage not available, using fallback storage.");
        }
    },

    /**
     * Get data
     * @param key
     * @returns {undefined}
     */
    getData(key) {
        let value = undefined;
        try {
            const _localStorage = localStorage || window.localStorage;
            value = JSON.parse(_localStorage[key]);
        } catch (ex) {
            return null;
        }

        return value;
    }
};

export default localStorageUtils;
