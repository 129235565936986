import config from "../../config";
import axios from "../axios";
import errorHandler from "../error-handler";

export const login = ({ username, password }) => {
    return new Promise((resolve, reject) => {
        axios
            .post(
                "/auth/login",
                {
                    username,
                    password
                },
                {
                    headers: {
                        Authorization: `Basic ${config.API_CLIENT_BASIC}`
                    }
                }
            )
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(errorHandler(error));
            });
    });
};

export const refreshAccessToken = () => {
    return new Promise((resolve, reject) => {
        axios
            .post("/auth/refresh", null, {
                headers: {
                    Authorization: `Basic ${config.API_CLIENT_BASIC}`
                }
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(errorHandler(error));
            });
    });
};

export const invalidateAccessToken = () => {
    return new Promise((resolve, reject) => {
        axios
            .post("/auth/invalidate", null, {
                headers: {
                    Authorization: `Basic ${config.API_CLIENT_BASIC}`
                }
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(errorHandler(error));
            });
    });
};
