import {
    disableBodyScroll as disableBodyScrollFunction,
    enableBodyScroll as enableBodyScrollFunction
} from "body-scroll-lock";

export const disableBodyScroll = (targetElement) => {
    disableBodyScrollFunction(targetElement, {
        reserveScrollBarGap: true
    });
};

export const enableBodyScroll = (targetElement) => {
    enableBodyScrollFunction(targetElement);
};

export const disablePullRefresh = () => {
    document.body.style = "overscroll-behavior: contain;";
};

export const enablePullRefresh = () => {
    document.body.style["overscroll-behavior"] = null;
};
