const iosUtils = {
    isIOSAndSafari() {
        const userAgent = window.navigator.userAgent.toLowerCase();
        const safari = /safari/.test(userAgent);
        const ios = /iphone|ipod|ipad/.test(userAgent);
        return ios && safari;
    },

    /**
     * Check if in IOS web view
     * @returns {boolean}
     */
    isIOSWebView() {
        const standalone = window.navigator.standalone;
        const userAgent = window.navigator.userAgent.toLowerCase();
        const safari = /safari/.test(userAgent);
        const ios = /iphone|ipod|ipad/.test(userAgent);
        return ios && !standalone && !safari;
    }
};

export default iosUtils;
