import axios from "axios";
import queryString from "query-string";
import config from "../config";

// Headers
let headers = {
    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
};

// Create
let instance = axios.create({
    baseURL: config.API_URL,
    timeout: 60000,
    headers: headers,
    withCredentials: true
});

/**
 * Interceptor:
 * REQUESTS
 */
instance.interceptors.request.use(
    function (cfg) {
        // Check if external request
        if (cfg.url.indexOf("amazonaws.com") >= 0) {
            delete cfg.headers["Authorization"];
        }

        // Process request body
        else if (cfg.data) {
            cfg.data = queryString.stringify(cfg.data);
        }

        return cfg;
    },
    function (error) {
        console.log("[VP] Request error:", error);
    }
);

export default instance;
