export const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ssZ";

export const APP_ENV = process.env.APP_ENV;
export const BUILD_VERSION = process.env.BUILD_VERSION;
export const BUILD_TIMESTAMP = process.env.BUILD_TIMESTAMP;

export const BREAKPOINTS = {
    600: "mobile",
    900: "tablet",
    1400: "desktop",
    1800: "widescreen"
};

export const AUTH_STORAGE_KEY = "vessel_plus_auth";
export const USER_EMAIL_STORAGE_KEY = "vessel_plus_email";
export const SIGNUP_TUTORIAL_STORAGE_KEY = "vessel_plus_signup_tutorial";
export const VESSEL_TASKS_PERIOD_FILTER = "vessel_plus_tasks_period_filter";
export const DEVIATION_LIST_FILTERS = "vessel_plus_deviation_filters";
export const INVENTORY_LIST_VIEW_MODE = "vessel_plus_inventory_view_mode";
