import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";

export const useDebounce = (callback, values, delay = 500, skipFirst = true) => {
    const firstRun = useRef(true);
    const callbackTimeout = useRef();

    useEffect(() => {
        if (firstRun.current && skipFirst) {
            firstRun.current = false;
            return;
        }

        if (callback) {
            clearTimeout(callbackTimeout.current);
            callbackTimeout.current = setTimeout(() => {
                callback();
            }, delay || 500);
        }

        return () => {
            clearTimeout(callbackTimeout.current);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, values);
};

export const useOutsideClick = (callback) => {
    const ref = useRef();

    useEffect(() => {
        const handleClick = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        };

        document.addEventListener("click", handleClick, true);

        return () => {
            document.removeEventListener("click", handleClick, true);
        };
    }, [ref, callback]);

    return ref;
};

export const useIsMounted = () => {
    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    });

    return isMounted;
};

export const useLocalStorage = (key, initialValue) => {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = typeof window !== "undefined" ? window.localStorage.getItem(key) : "";
            return item && item !== "undefined" ? JSON.parse(item) : initialValue;
        } catch (error) {
            return initialValue;
        }
    });

    const setValue = (value) => {
        try {
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            setStoredValue(valueToStore);
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            console.error(error);
        }
    };

    return [storedValue, setValue];
};

export const useQueryParam = (paramName) => {
    const location = useLocation();
    const history = useHistory();
    const queryParamValue = new URLSearchParams(location.search).get(paramName);

    const setQueryParam = useCallback(
        (value) => {
            const searchParams = new URLSearchParams(location.search);

            if (value) {
                searchParams.set(paramName, value);
            } else {
                searchParams.delete(paramName);
            }

            history.push({ search: searchParams.toString() });
        },
        [history, location.search, paramName]
    );

    useEffect(() => {
        // Ensure the query parameter stays in sync with the URL
        const currentQueryParam = new URLSearchParams(location.search).get(paramName);
        if (currentQueryParam !== queryParamValue) {
            setQueryParam(queryParamValue);
        }
    }, [paramName, queryParamValue, location.search, setQueryParam]);

    return [queryParamValue, setQueryParam];
};
